import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ResultRow from './ResultRow';
import NavBar from './NavBar';
import { getAllNewYearsAndTutes, fetchResultsForStudent } from '../../services/services';
import NewResultRow from './newResultRow';

function StudentResult() {
    const location = useLocation();
    const { results } = location.state || {};
    const searchedStudent = results?.searchedStudentResult?.[0];
    const topResults = results?.top10Results;

    const [availableYears, setAvailableYears] = useState(new Set());
    const [indexNo, setIndexNo] = useState("");
    const [year, setYear] = useState("");
    const [tute, setTute] = useState("");
    const [errorMsg, setErrorMsg] = useState(""); // State for error message
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const yearsAndTutes = await getAllNewYearsAndTutes();
            if (yearsAndTutes) {
                setAvailableYears(new Set(yearsAndTutes.map(yt => yt.year).sort()));
            }
        })();
    }, []);

    const handleSearch = async () => {
        if (!indexNo || !year || !tute) {
            setErrorMsg("Index, Year & Paper No are required!"); // Set error message
            return;
        }
        setErrorMsg(""); // Clear error message if all fields are filled
        const results = await fetchResultsForStudent(indexNo, parseInt(year, 10), tute);
        navigate('/StudentResult', { state: { results } });
    };

    const handleReset = () => {
        setYear("");
        setTute("");
        setIndexNo("");
        setErrorMsg(""); // Clear error message on reset
    };

    return (
        <div className="flex flex-col min-h-screen">
            <NavBar />

            {/* Added mt-16 to push the content down below the fixed NavBar */}
            <div className="flex flex-col items-center p-4 mt-16">
                <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4 w-full max-w-screen-lg">
                    <input
                        type="text"
                        value={indexNo}
                        placeholder="Index Number"
                        className="border border-[#1BA147] py-2 px-4 text-sm focus:outline-none focus:ring focus:border-green-500 font-segoe placeholder-italic w-full md:w-auto"
                        onChange={(e) => setIndexNo(e.target.value)}
                    />

                    <input
                        type="text"
                        value={tute}
                        placeholder="Paper No"
                        className="border border-[#1BA147] py-2 px-4 text-sm focus:outline-none focus:ring focus:border-green-500 font-segoe placeholder-italic w-full md:w-auto no-spinner"
                        onChange={(e) => setTute(e.target.value)}
                    />

                    <select
                        className="border border-[#1BA147] py-2 px-4 text-sm focus:outline-none focus:ring focus:border-green-500 font-segoe w-full md:w-auto"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                    >
                        <option value="">Year</option>
                        {[...availableYears].map(eachYear => (
                            <option key={eachYear} value={eachYear}>{eachYear}</option>
                        ))}
                    </select>

                    <button
                        className="bg-green-600 text-white py-2 px-6 rounded hover:bg-green-700 border-[#1BA147] font-segoe w-full md:w-auto"
                        onClick={handleSearch}
                    >
                        Search
                    </button>
                    <button
                        className="border border-[#1BA147] text-[#1BA147] py-2 px-6 rounded hover:bg-green-700 hover:text-white font-segoe w-full md:w-auto"
                        onClick={handleReset}
                    >
                        Reset
                    </button>
                </div>

                {/* Error Message */}
                <div className="min-h-[20px] mt-4 font-weight-600">
                    {errorMsg ? (
                        <p className="text-red-500">{errorMsg}</p>
                    ) : (
                        // Empty paragraph to maintain space
                        <p>&nbsp;</p>
                    )}
                </div>

                <div className="w-full mt-8">
                    {searchedStudent ? (
                        <div className="text-center mb-8">
                            <h3 className="text-lg font-semibold bg-custom-light-green-50 p-2 rounded">Searched Student's Result</h3>
                            <div className="overflow-x-auto mt-4">
                                <table className="bg-white border-collapse border border-gray-300 mx-auto w-full sm:w-4/5">
                                    <thead>
                                        <tr className="bg-green-10">
                                            <th className="w-24 py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300">Rank</th>
                                            <th className="w-32 py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300">Index Number</th>
                                            <th className="w-48 py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300">Name</th>
                                            <th className="w-24 py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300">Mark</th>
                                            <th className="w-24 py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300">History</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center text-sm font-semibold text-gray-700">
                                        <NewResultRow result={searchedStudent} />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        <p className="text-center mt-4 mb-4 text-red-600">No result found for the searched Index Number.</p>
                    )}

                    <div className="text-center mb-8">
                        <h3 className="text-lg font-semibold bg-custom-light-green-50 p-2 rounded">Top 10 Students</h3>
                        <div className="overflow-x-auto overflow-y-auto mt-4 max-h-96">
                            <table className="bg-white border-collapse border border-gray-300 mx-auto w-full sm:w-4/5">
                                <thead>
                                    <tr className="bg-green-10">
                                        <th className="w-24 py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300">Rank</th>
                                        <th className="w-32 py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300">Index Number</th>
                                        <th className="w-48 py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300">Name</th>
                                        <th className="w-24 py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300">Mark</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center text-sm font-semibold text-gray-700">
                                    {topResults?.map((result, index) => (
                                        <ResultRow key={index} result={result} />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StudentResult;
