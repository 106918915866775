// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_STORAGE_API_KEY,
    projectId: "syncturma-team-chat-01",
    storageBucket: "syncturma-team-chat-01.appspot.com",
};

// Initialize Firebase
const storageApp = initializeApp(firebaseConfig, "syncteamStorage");

export const storageAuth = getAuth(storageApp);

export const storage = getStorage(storageApp);