import React, { useEffect, useState } from "react";
import NavBar from "../navBar/navBar";
import { updateYearAndTute } from "../../services/services";
import { useLocation, useNavigate } from "react-router-dom";
import PopUpModal from "../addMarks/PopUpModal";
import { MdDeleteOutline } from "react-icons/md";

export default function EditTute() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const year = queryParams?.get("year");
  const tuteNumber = queryParams?.get("tute");
  const fileURL =
    queryParams?.get("fileURL") && decodeURI(queryParams?.get("fileURL"));

  const [updateYear, setUpdateYear] = useState("");
  const [updateTute, setUpdateTute] = useState("");
  const [removeFile, setRemoveFile] = useState(false);
  const [file, setFile] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [modalMessage, setModalMessage] = useState(""); // Modal message state

  useEffect(() => {
    (async () => {
      if (year && tuteNumber) {
        setUpdateYear(year);
        setUpdateTute(tuteNumber);
      }
    })();
  }, [year, tuteNumber]);

  const updateCurrentYearTute = async (e) => {
    e.preventDefault();

    if (!updateTute || !updateYear) {
      setErrorMessage("All fields are required!");
      return;
    }

    setErrorMessage("");

    const modifiedYearTute = {
      year: parseInt(updateYear, 10),
      tute: updateTute,
    };

    const currentFileName = fileURL?.split('%2F').pop().split('?')[0];

    const response = await updateYearAndTute(parseInt(year, 10), tuteNumber, modifiedYearTute, removeFile, currentFileName, file);

    // Use existing fileURL if no new file is uploaded
    /*const fileToUpload = file ? file : fileURL;

    const response = await updateYearAndTute(parseInt(year, 10), tuteNumber, modifiedYearTute, fileToUpload);*/
    if (response.isSuccess) {
      setModalMessage(response.message);
      setShowModal(true);
      navigate(`/AddTute`);
    } else {
      setModalMessage(response.message);
      setShowModal(true);
    }

    if (file) {
      setFile(null);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center">
      <header className="w-full bg-green-100 py-4 px-6 flex justify-between items-center custom-shadow">
        <NavBar />
      </header>

      <main className="flex flex-col items-center w-full mt-8 px-4">
        <h1 className="text-2xl font-weight-600 text-black mb-4 self-start ml-4 font-segoe mt-4 md:ml-12">
          Edit Paper
        </h1>
        <div className="bg-[#FFFFFF] p-4 md:p-6 rounded-[10px] w-full md:w-[95%]  md:h-[70vh] h-[76vh] md:h-[70vh] border custom-borderMarks mt-4 relative">
          <form onSubmit={updateCurrentYearTute} className="space-y-4">
            <div className="flex flex-col md:flex-row md:space-x-4 p-4 md:p-8">
              <div className="w-full md:w-1/3 mt-4 md:mt-10">
                <input
                  type="number"
                  className="block w-full md:w-[60%] p-2 border border-[#1BA147] placeholder-italic no-spinner "
                  placeholder="Year"
                  value={updateYear}
                  onChange={(e) => setUpdateYear(e.target.value)}
                />
              </div>
              <div className="w-full md:w-1/3 mt-4 md:mt-10">
                <input
                  type="text"
                  className="block w-full md:w-[60%] p-2 border border-[#1BA147] placeholder-italic no-spinner"
                  placeholder="Paper No"
                  value={updateTute}
                  onChange={(e) => setUpdateTute(e.target.value)}
                />
              </div>
              <div className="w-full md:w-1/3 mt-4 md:mt-10">
                <input
                  type="file"
                  placeholder="Answers file"
                  className="w-full border border-[#1BA147] px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-green-500 bg-[#FFFFFF] placeholder-italic no-spinner"
                  onChange={(e) => {
                    const selectedFile = e.target.files[0];

                    if (selectedFile) {
                      if (selectedFile.type !== "application/pdf") {
                        setErrorMessage("Only PDF files are allowed.");
                        setFile(null);
                        return;
                      }

                      if (selectedFile.size > 5 * 1024 * 1024) {
                        // 5MB in bytes
                        setErrorMessage("File size must not exceed 5MB.");
                        setFile(null);
                        return;
                      }

                      setErrorMessage(""); // Clear error message
                      setFile(selectedFile); // Set the valid file
                    } else {
                      setFile(null);
                    }
                  }}
                  accept="application/pdf"
                />
                {/* Display current file link or name if available */}
                {fileURL && !removeFile ? (
                  <div className="mb-2 flex items-center space-x-2">
                    <p className="text-sm text-gray-500">
                      Current file:
                      <a
                        href={fileURL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        {fileURL.split("%2F").pop().split("?")[0].length > 15
                          ? fileURL
                              .split("%2F")
                              .pop()
                              .split("?")[0]
                              .slice(0, 15) + "..."
                          : fileURL.split("%2F").pop().split("?")[0]}
                      </a>
                    </p>
                    <button
                      type="button"
                      className="text-red-500 hover:text-red-700 "
                      onClick={() => {
                        setFile(null);         // Clear the file state
                        setErrorMessage("");   // Clear error message
                        setRemoveFile(true);
                      }}
                    >
                      <MdDeleteOutline size={20} />
                    </button>
                  </div>
                ) : null}


              </div>
            </div>

            {errorMessage ? (
              <div className="text-red-500 text-center mb-4">
                <p className="font-weight-600">{errorMessage}</p>
              </div>
            ) : (
              <p className="mb-4">&nbsp;</p>
            )}

            <div className="absolute bottom-4 right-4">
              <button
                type="submit"
                className="bg-[#1BA147] text-white px-4 py-2 md:px-6 md:py-3 rounded-md hover:bg-green-600"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </main>
      {showModal && (
        <PopUpModal
          message={modalMessage}
          closeModal={() => setShowModal(false)}
        />
      )}
    </div>
  );
}
