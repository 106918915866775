import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function NewResultRow({ result }) {
    const navigate = useNavigate();

    const handleHistoryClick = () => {
        navigate(`/History/${result?.indexNumber}`, { state: { result } });
    };

    return (
        <tr>
            <td className="table-cell">{result?.rank}</td>
            <td className="table-cell">{result?.indexNumber}</td>
            <td className="table-cell">{result?.name?.length > 30 ? result.name.slice(0, 30) + '...' : result.name}</td>
            <td className="table-cell">{result?.mark}</td>
            <td className="table-cell">
                <button
                    className="bg-green-600 text-white py-2 px-6 rounded hover:bg-green-700 border-[#1BA147] font-segoe w-full md:w-auto"
                    onClick={handleHistoryClick}
                >
                    View History
                </button>
            </td>
        </tr>
    )
}
