import React, { useState } from "react";
import NavBar from "../home/NavBar";
import "../../App.css";

const TimeTable = () => {
  // Custom timetable data
  const timetableData = [
    { time: "9:00 AM - 10:00 AM", monday: "Math", tuesday: "Physics", wednesday: "Chemistry", thursday: "Biology", friday: "History" },
    { time: "10:00 AM - 11:00 AM", monday: "English", tuesday: "Math", wednesday: "Physics", thursday: "Chemistry", friday: "Biology" },
    { time: "11:00 AM - 12:00 PM", monday: "Computer Science", tuesday: "History", wednesday: "Math", thursday: "Physics", friday: "English" },
    { time: "12:00 PM - 1:00 PM", monday: "Physics", tuesday: "Biology", wednesday: "English", thursday: "Math", friday: "Chemistry" },
    { time: "1:00 PM - 2:00 PM", monday: "Lunch Break", tuesday: "Lunch Break", wednesday: "Lunch Break", thursday: "Lunch Break", friday: "Lunch Break" },
    { time: "2:00 PM - 3:00 PM", monday: "History", tuesday: "Chemistry", wednesday: "Biology", thursday: "Computer Science", friday: "Physics" },
    { time: "3:00 PM - 4:00 PM", monday: "Chemistry", tuesday: "Computer Science", wednesday: "History", thursday: "English", friday: "Math" },
  ];

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 bg-[#7DE96D]">
      {/* Fixed Navigation Bar */}
      <NavBar />

      {/* Time Table Header */}
      <section className="flex flex-col items-center justify-center text-center py-20 bg-[#7DE96D] text-black">
        <h1 className="text-4xl font-bold">Class Time Table</h1>
        <p className="text-lg">View class schedule</p>
        <div className="bg-[#DCFCE76B] mt-6 text-center w-[70%] p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-[#333333] mb-4">2025 A/L</h2>
          <h2 className="text-2xl font-bold text-[#333333] mb-4 text-left">සිද්ධාන්ත</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">Group I</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">ඉරිදා පෙ.ව. 7.30 - පෙ.ව. 11.00</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">Group II</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">අගහරුවාදා  ප.ව. 2.30 - ප.ව. 6.00</h2>

          <h2 className="text-2xl font-bold text-[#333333] mb-4 text-left">Paper Class</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">Group I</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">සෙනසුරාදා පෙ.ව. 7.30 - ප.ව. 12.00</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">Group II</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">බ්‍රහස්පතින්දා ප.ව 3.30 - ප.ව 9.00</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ">(2025 සියලු පන්ති ඇල්පිටිය සැසිප් ආයතනයේදී...)</h2>

          
        </div>

        <div className="bg-[#DCFCE76B] mt-6 text-center w-[70%] p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-[#333333] mb-4">2026 A/L</h2>
          <h2 className="text-2xl font-bold text-[#333333] mb-4 text-left">සිද්ධාන්ත</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">Group I</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">ඉරිදා පෙ.ව. 11.00 - ප.ව 2.00</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">Group II</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">සිකුරාදා ප.ව. 2.30 - ප.ව 5.30</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ">(Group I හා Group II ඇල්පිටිය සැසිප් ආයතනයේදී...)</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">Group III</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">ඉරිදා ප.ව. 3.00 - ප.ව 6.00</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ">(Group III අම්බලන්ගොඩ Maxma ආයතනයේදී...)</h2>
        
        </div>

        <div className="bg-[#DCFCE76B] mt-6 text-center w-[70%] p-6 rounded-lg shadow-lg">
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">2026 A/L කණ්ඩායම් පන්ති(Small Group)</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">බදාදා ප.ව. 6.00 - ප.ව. 9.30</h2>

          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">Paper Class</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">බ්‍රහස්පතින්දා පෙ.ව. 11.30 - ප.ව. 5.30</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ">(අම්බලන්ගොඩ Maxma ආයතනයේදී...)</h2>

          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">2025 A/L Rivision Class</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ml-6">බදාදා පෙ.ව. 11.00 - ප.ව. 6.30</h2>
          <h2 className="text-xl font-bold text-[#333333] mb-4 text-left ">(ඇල්පිටිය සැසිප් ආයතනයේදී...)</h2>

          
        </div>

      </section>
        
      {/* Time Table Content */}
      {/* <section className="flex flex-col items-center py-10 px-6">
        <div className="overflow-x-auto w-full">
          <table className="table-auto w-full max-w-6xl mx-auto bg-white shadow-lg rounded-lg">
            <thead>
              <tr className="bg-blue-600 text-white">
                <th className="px-4 py-2">Time</th>
                <th className="px-4 py-2">Monday</th>
                <th className="px-4 py-2">Tuesday</th>
                <th className="px-4 py-2">Wednesday</th>
                <th className="px-4 py-2">Thursday</th>
                <th className="px-4 py-2">Friday</th>
              </tr>
            </thead>
            <tbody>
              {timetableData.length > 0 ? (
                timetableData.map((row, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
                    }`}
                  >
                    <td className="border px-4 py-2">{row.time}</td>
                    <td className="border px-4 py-2">{row.monday}</td>
                    <td className="border px-4 py-2">{row.tuesday}</td>
                    <td className="border px-4 py-2">{row.wednesday}</td>
                    <td className="border px-4 py-2">{row.thursday}</td>
                    <td className="border px-4 py-2">{row.friday}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="6"
                    className="text-center text-gray-500 py-6"
                  >
                    No timetable data available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section> */}

     
    </div>
  );
};

export default TimeTable;
