import React, { useState } from 'react';
import { AiOutlineUser } from "react-icons/ai";
import { MdLockOutline } from "react-icons/md";
import { IoEyeOutline } from 'react-icons/io5';
import { FiEyeOff } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { doSignInWithEmailAndPassword } from '../../firebase/Auth';
import "../../App.css";
import { signInWithEmailAndPassword } from 'firebase/auth';
import { storageAuth } from '../../firebase/FirebaseStorageSyncTeam';

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // Single state for combined error message
  const navigate = useNavigate();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let isValid = true;
    setErrorMessage(""); // Reset error message

    if (!email && !password) {
      setErrorMessage("Email and Password are required");
      isValid = false;
    } else if (!email) {
      setErrorMessage("Email is required");
      isValid = false;
    }
    else if (!validateEmail(email)) {
      setErrorMessage("Invalid email format");
      isValid = false;
    }
    else if (!password) {
      setErrorMessage("Password is required");
      isValid = false;
    }

    if (!isValid) return;

    // User authentication using Firebase
    doSignInWithEmailAndPassword(email, password)
      .then(() => {
        signInWithEmailAndPassword(storageAuth, process.env.REACT_APP_FIREBASE_STORAGE_USER, process.env.REACT_APP_FIREBASE_STORAGE_PASS)
          .then((userCredential) => {
            console.log("User signed in for Firebase Storage.");
          })
          .catch((error) => {
            console.error("Firebase Storage account authentication error:", error);
          });
      })
      .then(() => {
        navigate('/Dashboard');
      })
      .catch((error) => {
        console.log(error);
        // Check for specific error codes
        if (error.code.includes('wrong-password')) {
          setErrorMessage('Incorrect password. Please try again.');
        } else if (error.code.includes('user-not-found')) {
          setErrorMessage('Email not found. Please check your email.');
        }
        else if (error.code.includes('INVALID_EMAIL')) {
          setErrorMessage('INVALID_EMAIL');
        } else {
          setErrorMessage('Incorrect Email or Password. Please try again.'); // General error message
        }
      });
  };

  return (
    <div className="flex flex-wrap h-screen w-full bg-[#DCFCE7]">
      {/* Left side - Background Image */}
      <div className="w-full md:w-1/2 h-1/2 md:h-full bg-cover bg-center" style={{ backgroundImage: "url('/newbg.jpg')" }}>
      </div>

      {/* Right side - Login Form */}
      <div className="w-full md:w-1/2 flex items-center justify-center h-1/2 md:h-full">
        <div className="w-[80%] md:w-[60%]">
          <h2 className="text-[#1BA147] text-[32px] font-bold leading-[42.56px] text-center font-segoe font-weight-700">Login</h2>

          <div className="relative mt-10 mb-4">
            <input
              className={`shadow appearance-none border rounded-custom w-full py-2 px-3 text-black-700 leading-tight pl-10 pr-3 focus:outline-none focus:shadow-outline placeholder-italic ${errorMessage ? 'border-red-500' : ''}`}
              id="username"
              type="text"
              placeholder="Enter your Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="absolute inset-y-0 left-3 flex items-center">
              <AiOutlineUser className="text-[#1BA147] w-5 h-5" />
            </div>
          </div>

          <div className="relative mt-10 mb-4">
            <input
              className={`shadow appearance-none border rounded-custom w-full py-2 px-3 text-black-700 leading-tight pl-10 pr-12 focus:outline-none focus:shadow-outline placeholder-italic ${errorMessage ? 'border-red-500' : ''}`}
              id="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter your Password"
              onChange={(e) => setPassword(e.target.value)}
            // autoComplete="off" // Disable Edge's password reveal icon
            // inputMode="none" // Optional, helps prevent unwanted input modes
            />
            <div className="absolute inset-y-0 left-3 flex items-center">
              <MdLockOutline className="text-[#1BA147] w-5 h-5" />
            </div>
            <button
              type="button"
              onClick={handleTogglePassword}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#77A507] focus:outline-none"
            >
              {showPassword ? <IoEyeOutline className='w-5 h-5 text-[black]' /> : <FiEyeOff className='w-5 h-5 text-[black]' />}
            </button>
          </div>

          {/* Combined Error Message with placeholder div */}
          <div className="flex justify-center mb-4" style={{ minHeight: '24px' }}>
            {errorMessage ? (
              <p className="text-red-500 text-sm font-weight-600">{errorMessage}</p>
            ) : (
              <div className="h-6"></div> // Placeholder div for maintaining space
            )}
          </div>

          <div className="flex items-center justify-between">
            <button
              className="bg-[#1BA147] hover:bg-[#178C3A] text-[#FFFFFF] hover:text-[#FFFFFF] font-weight-700 py-2 px-4 border rounded-custom focus:outline-none focus:shadow-outline w-full"
              type="button"
              onClick={handleSubmit}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
