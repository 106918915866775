import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doSignOut } from '../../firebase/Auth';
import { storageAuth } from '../../firebase/FirebaseStorageSyncTeam';

export default function NavBar() {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogout = () => {
    doSignOut().then(storageAuth.signOut()).then(() => {
      navigate('/65fg4654bfdf48fdsjxkmm-Login')
    })
  };

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="fixed top-0 left-0 w-full bg-[#DCFCE7] z-10 custom-shadow">
      <div className="container mx-auto flex flex-wrap items-center justify-between px-4 py-2">
        <div className="flex flex-wrap space-x-4 sm:space-x-8">
          <a
            href="/Dashboard"
            className={`${isActive('/Dashboard') ? 'text-green-600' : 'text-black'
              } hover:text-green-600 font-weight-600 text-sm sm:text-base`}
          >
            Dashboard
          </a>
          <a
            href="/AddMarks"
            className={`${isActive('/AddMarks') ? 'text-green-600' : 'text-black'
              } hover:text-green-600 font-weight-600 text-sm sm:text-base`}
          >
            Add Marks
          </a>
          {/* <a
            href="#ranking"
            className={`${
              isActive('/ranking') ? 'text-green-600' : 'text-black'
            } hover:text-green-600 font-weight-600 text-sm sm:text-base`}
          >
            Ranking
          </a>
          <a
            href="#top-students"
            className={`${
              isActive('/top-students') ? 'text-green-600' : 'text-black'
            } hover:text-green-600 font-weight-600 text-sm sm:text-base`}
          >
            Top students
          </a> */}
          <a
            href="/Settings"
            className={`${isActive('/Settings') ? 'text-green-600' : 'text-black'
              } hover:text-green-600 font-weight-600 text-sm sm:text-base`}
          >
            Settings
          </a>
        </div>
        <div className="relative mt-4 sm:mt-0 rounded-full bg-white w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center mr-10">
          <img
            src="profile.jpg"
            alt="Profile"
            className="rounded-full w-full h-full object-cover cursor-pointer"
            onClick={toggleDropdown}
          />
          {dropdownVisible && (
            <div className="absolute mt-16 sm:mt-20 w-32 bg-white border border-[#0000004A] rounded-[5px] custom-shadow font-weight-400">
              <a
                href="#signout"
                className="block px-4 py-2 text-gray-800 hover:bg-gray-100 text-center font-weight-400"
                onClick={handleLogout}
              >
                Log out
              </a>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}
