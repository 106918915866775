import React, { useEffect, useState } from "react";
import { fetchResultsByYearTuteIndex, fetchStudentResultsHistory } from "../../services/services";
import NavBar from "../home/NavBar";
import "../../App.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate, useParams } from "react-router-dom";

const History = () => {
  const { index } = useParams(); // Extract the 'index' parameter from the URL
  const location = useLocation();
  const { result } = location.state || {};

  const [availableResults, setAvailableResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState(""); // Error message state
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const navigate = useNavigate();

  // Pagination
  const totalPages = Math.ceil(availableResults?.length / rowsPerPage);
  const paginatedResults = availableResults?.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const getPaginationButtons = () => {
    const maxButtons = 5; // Number of buttons to show at a time
    const firstPage = 1;
    const lastPage = totalPages;
    let startPage, endPage;

    if (totalPages <= maxButtons) {
      // If total pages are less than maxButtons, show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 4) {
        // Show first pages and the last page
        startPage = 1;
        endPage = Math.min(maxButtons, totalPages);
      } else if (currentPage >= totalPages - 4) {
        // Show last pages and the first page
        startPage = Math.max(totalPages - maxButtons + 1, 1);
        endPage = totalPages;
      } else {
        // Show pages around the current page with gaps
        const gap = Math.floor(maxButtons / 2);
        startPage = currentPage - gap;
        endPage = currentPage + gap;

        if (startPage < 1) {
          startPage = 1;
          endPage = Math.min(maxButtons, totalPages);
        }

        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = Math.max(totalPages - maxButtons + 1, 1);
        }
      }
    }

    // Prepare pagination buttons
    const buttons = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

    // Include first page and last page if they are not in the current range
    if (startPage > 2) {
      buttons.unshift(firstPage); // Add the first page if it's not already in the buttons
    }

    if (endPage < totalPages - 1) {
      buttons.push(lastPage); // Add the last page if it's not already in the buttons
    }

    return buttons;
  };

  const paginationButtons = getPaginationButtons();

  const goToPage = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    (async () => {
      if (index) {
        const results = await fetchStudentResultsHistory(index);
        results && setAvailableResults(results);
      }
    })();
  }, [index]);

  //   const handleSearch = async () => {
  //     // Reset error message
  //     setErrorMessage("");

  //     if (!year || !tute) {
  //       setErrorMessage("Paper No and Year are required!");
  //       return;
  //     }

  //     const results = (await fetchResultsByYearTuteIndex(indexNo, parseInt(year, 10), tute)).sort((a, b) => (a.rank - b.rank));
  //     setAvailableResults(results);
  //     setCurrentPage(1);
  //   };

  const handleDownload = (url) => {
    if (!url) {
      alert("Download link not available");
      return;
    }

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="min-h-screen bg-[#FFFFFF] flex flex-col">
      {/* Header */}
      <header className="flex items-center p-4">
        <ArrowBackIcon
          className="cursor-pointer text-green-700 hover:text-green-900"
          onClick={handleBackClick}
        />
        <h1 className="ml-4 text-2xl font-semibold">History</h1>
      </header>

      {/* Main Content */}
      <main className="container mx-auto py-8 px-4">
        {/* Search Form */}
        {/* <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4 mb-8 mt-8">
          <h1 className="text-xl md:text-2xl font-weight-600 text-black self-start ml-0 font-segoe">Result</h1>
          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 w-full md:w-auto">
            <input
              type="text"
              placeholder="Index Number"
              className="border border-[#1BA147] py-2 px-4 text-sm focus:outline-none focus:ring focus:border-green-500 w-full md:w-auto placeholder-italic"
              onChange={(e) => setIndexNo(e.target.value)}
            />
            <select
              className="border border-[#1BA147] py-2 px-4 text-sm focus:outline-none focus:ring focus:border-green-500 placeholder-italic w-full md:w-auto"
              onChange={(e) => {
                setYear(e.target.value);
                const availableTutes = allYearsAndTutes?.filter(ayt => (ayt.year === parseInt(e.target.value, 10)));
                availableTutes && setRelevantTutes(new Set(availableTutes.map(at => at.tute)));
              }}
            >
              <option value="">Year</option>
              {[...availableYears].map(eachYear => (
                <option key={eachYear} value={eachYear}>{eachYear}</option>
              ))}
            </select>
            <select className="mt-1 block w-full p-2 text-sm border border-[#1BA147]"
              onChange={(e) => setTute(e.target.value)}
              required>
              <option value="">Paper No</option>
              {[...relevantTutes].map(eachTute => (
                <option key={eachTute} value={eachTute}>{eachTute}</option>
              ))}
            </select>
            <button
              className="bg-green-600 text-white py-2 px-8 rounded-[5px] hover:bg-green-700 w-full md:w-auto border border-[#1BA147]"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div> */}

        {/* Error Message */}
        {/* <div className="text-red-500 text-sm font-semibold text-right mb-4 mr-52 whitespace-nowrap">
          {errorMessage ? (
            <p>{errorMessage}</p>
          ) : (
            <p>&nbsp;</p> // Empty paragraph for spacing when no error message
          )}
        </div> */}

        {/* Result Table */}
        <div className="rounded-[10px] border border-gray-300 overflow-x-auto mt-[2%]">
          <table className="w-full bg-white text-sm rounded-[10px]">
            <thead>
              <tr className="text-left">
                <th className="py-2 px-4 border-b border-gray-300 table-year text-center">Year</th>
                <th className="py-2 px-4 border-b border-gray-300 table-index text-center">Index no</th>
                <th className="py-2 px-4 border-b border-gray-300 table-name text-center">Name</th>
                <th className="py-2 px-4 border-b border-gray-300 table-tute text-center">Paper No</th>
                <th className="py-2 px-4 border-b border-gray-300 table-marks text-center">Marks</th>
                <th className="py-2 px-4 border-b border-gray-300 table-rank text-center">Ranks</th>
                <th className="py-2 px-4 border-b border-gray-300 table-action text-center">Answer</th>
              </tr>
            </thead>
            <tbody>
              {paginatedResults?.map((userResult, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b border-gray-300 table-cell text-center">{userResult.year}</td>
                  <td className="py-2 px-4 border-b border-gray-300 table-cell text-center">{userResult.indexNumber}</td>
                  <td className="py-2 px-4 border-b border-gray-300 table-cell text-center">{result?.name}</td>
                  <td className="py-2 px-4 border-b border-gray-300 table-cell text-center">{userResult.tuteNumber}</td>
                  <td className="py-2 px-4 border-b border-gray-300 table-cell text-center">{userResult.mark}</td>
                  <td className="py-2 px-4 border-b border-gray-300 table-cell text-center">{userResult.rank}</td>
                  <td className="py-2 px-4 border-b border-gray-300 table-cell text-center">
                    {userResult?.answersSheetUrl && <button
                      className="bg-green-600 text-white py-2 px-6 rounded hover:bg-green-700 border-[#1BA147] font-segoe w-full md:w-auto"
                      onClick={() => handleDownload(userResult.answersSheetUrl)}
                    >
                      Downlaod
                    </button>}
                  </td>
                </tr>
              ))}
              {/* Render empty rows if the data is less than rowsPerPage */}
              {paginatedResults.length < rowsPerPage &&
                [...Array(rowsPerPage - paginatedResults.length)].map((_, emptyRowIdx) => (
                  <tr key={`empty-${emptyRowIdx}`} className="hover:bg-gray-100 table-empty-row">
                    <td className="py-2 px-4 border-b border-gray-300 text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-300 text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-300 text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-300 text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-300 text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-300 text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-300 text-center">&nbsp;</td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="7" className="py-2 px-4 border-t border-gray-300">
                  <div className="flex justify-end">
                    <nav aria-label="Pagination" className="flex items-center">
                      <button
                        className="px-2 py-1 border rounded-l border-gray-300 bg-white hover:bg-gray-100 text-sm"
                        onClick={() => goToPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        &lt;
                      </button>
                      {paginationButtons.map((button, index) =>
                        button === '...' ? (
                          <span key={index} className="px-2 py-1 border border-gray-300 text-sm">...</span>
                        ) : (
                          <button
                            key={button}
                            onClick={() => goToPage(button)}
                            className={`px-2 py-1 border border-gray-300 text-sm ${currentPage === button ? 'bg-green-600 text-white' : 'bg-white hover:bg-gray-100 text-gray-700'}`}
                          >
                            {button}
                          </button>
                        )
                      )}
                      <button
                        className="px-2 py-1 border rounded-r border-gray-300 bg-white hover:bg-gray-100 text-sm"
                        onClick={() => goToPage(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        &gt;
                      </button>
                    </nav>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </main>
    </div>
  );
};

export default History;
